.report-date-time-filter {
  display: flex;
  justify-content: flex-end;
}
.Filter {
  margin: 25px 0 20px 0;
  display: flex;
  justify-content: space-between;
}
.MuiTableCell-root.MuiTableCell-body {
  word-break: break-word;
  max-width: 30vw;
}
