.date-time-filter {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    min-width: 600px;
    gap: 10px;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }